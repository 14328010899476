<template>
  <div class="home_top">

    <div class="com">
      <table style="width:100%">
        <tr>
          <td
            class="logo_img"
            style="width:20%"
          >
            <img
              @click="$router.push('/')"
              :src="$config.baseUrl+logo"
              alt=""
            />
          </td>
          <td
            style="width:10%"
            v-if='!isShow_nav'
          >

            <el-dropdown>
              <span
                class="el-dropdown-link"
                @click="$router.push({path:'/' })"
              >
                首页<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <p @click="$router.push({path:'/brands',query:{id:'0'}})">品牌供应商 <span class="themefont"> </span> </p>
                </el-dropdown-item>
                <el-dropdown-item>
                  <p>4s旗舰店 <span class="themefont"> </span> </p>
                </el-dropdown-item>
                <el-dropdown-item>
                  <p @click="toLive">仪商直播 <span class="themefont"> </span> </p>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>

          </td>
          <td class="center">
            <HomeSerach
              style="width:100%"
              :searchValue='value'
              :shopid='shopid'
            />
            <ul>
              <li
                class="hover"
                v-for='(item,index) in words'
                :key='index'
                @click="search(item)"
              >{{item.keywords}}</li>

            </ul>
          </td>
          <td style="width:20%">
            <div class="code">
              <el-image
                :src="url"
                :preview-src-list="srcList"
              >
              </el-image>
            </div>
          </td>

        </tr>
      </table>

      <div class="nav">
        <div
          v-if='isShow_nav'
          class='sort'
          @mouseover="scover()"
          @mouseleave="scleave()"
        >
          <p style="font-size: 16px;line-height:38px;">
            <i
              style="font-size: 18px"
              class="el-icon-s-grid"
            ></i> 商品分类

          </p>
          <transition
            name="overcat"
            v-if='!block'
          >

            <div
              class="B_l overBox"
              v-show="Scboxshow"
            >
              <div class="B_l_list">
                <ul class="cat_ul">
                  <li
                    v-for="(item,index) in Category"
                    :key="index"
                    class="cat"
                  >
                    <div
                      class="tit"
                      @mouseover="catMouseover(item.cc)"
                    >{{ item.cate_name }} <i class="el-icon-arrow-right"></i></div>

                    <div class="usually">
                      <div
                        class="item"
                        v-for='(u,i) in usually[index]'
                        @click="
                         toSort({id:u.id1,cate_name:u.name1},{id:u.id2,cate_name:u.name2})
                        "
                        :key='i'
                      >{{u.name2}} </div>

                    </div>
                  </li>
                  <div
                    class="catList"
                    v-if="catlUL"
                  >
                    <ul @mouseleave="catMouseleave()">
                      <li
                        v-for="(item1, index1) in catmouList"
                        :key="index1"
                      >
                        <div
                          class="title"
                          @click="
                         toSort(item1,'')
                        "
                        >{{ item1.cate_name }}</div>
                        <dl>
                          <dd
                            @click="
                         toSort(item1,item2)
                        "
                            v-for="(item2, index2) in item1.cc"
                            :key="index2"
                          >
                            {{ item2.cate_name }}
                          </dd>
                        </dl>
                      </li>
                    </ul>
                  </div>
                </ul>
              </div>
            </div>
          </transition>
        </div>

        <ul
          class="com_ul"
          v-if='isShow_nav'
        >
          <li
            @click="toHome"
            :class="{a:number==0}"
          ><i class="el-icon-s-home"></i> 首页</li>
          <li
            @click="toBrands"
            :class="{a:number==1}"
          >
            <i class="el-icon-s-platform"></i> 品牌供应商
          </li>
          <li
            @click="toship"
            :class="{a:number==2}"
          >
            <i class="el-icon-s-flag"></i> 4S旗舰店
          </li>

          <li
            @click="toLive"
            :class="{a:number==3}"
          ><i class="el-icon-video-camera-solid"></i>仪商直播</li>

          <li
            @click="toChain"
            :class="{a:number==4}"
          ><i class="el-icon-share"></i>仪商链</li>

          <li
            @click="toMaterial"
            :class="{a:number==5}"
          ><i class="el-icon-s-shop"></i>物料采购</li>
        </ul>

      </div>

    </div>

  </div>

</template>
<script>
import HomeSerach from "../home-search";
import OverCart from "../over-cart";

export default {
  data() {
    return {
      words: [],
      number: 0,
      catmouList: [],
      catlUL: false,
      searchData: {
        name_id: "",
        title: "",
        goodsname: "",
        sale_num_pr: undefined,
        price_pr: undefined,
        page: 1,
        limit: 30
      },
      url: require("@/assets/images/code.png"),
      srcList: [require("@/assets/images/code.png")],
      boxshow: false,
      Scboxshow: false,
      NumData: "",
      carData: "",
      logo: "",
      usually: [
        [
          { name1: "电子测量仪器", id1: 121, name2: "示波器", id2: 214 },
          { name1: "电子测量仪器", id1: 121, name2: "热像仪", id2: 162 }
        ],
        [
          { name1: "电力电工仪表", id1: 220, name2: "万用表", id2: 229 },
          { name1: "电力电工仪表", id1: 220, name2: "钳形表", id2: 230 }
        ],
        [
          { name1: "自动化仪表", id1: 168, name2: "压力仪表", id2: 224 },
          { name1: "自动化仪表", id1: 168, name2: "流量仪表", id2: 209 }
        ]
      ],
      //   [
      //     { name2: "温度传感器", id2: 247 },
      //     { name2: "流量传感器", id2: 225 }
      //   ],
      //   [
      //     { name: "光谱仪", id: 270 },
      //     { name: "频谱分析仪", id: 273 }
      //   ],

      // [
      //     { name: "有毒有害气体检测", id: 288 },
      //     { name: "温湿度记录仪", id: 301 }
      //   ],

      lists: [],
      Category: []
    };
  },

  props: ["value", "shopid"],

  components: {
    HomeSerach
  },
  watch: {},
  computed: {
    //判断页面显示导航
    isShow_nav() {
      console.log(" this.$route", this.$route);
      if (
        this.$route.path == "/" ||
        this.$route.path == "/home" ||
        this.$route.path == "/flagShip" ||
        this.$route.path == "/brands" ||
        this.$route.path == "/chain"||
        this.$route.path == "/material"||
        this.$route.path == "/PublicSearchBar" ||
        this.$route.path == "/sortProduct" ||
        this.$route.path == "/GeneralShop"
      ) {
        return true;
      } else {
        return false;
      }
    },
    block() {
      console.log(" this.$route", this.$route);
      if (this.$route.path == "/" || this.$route.path == "/home") {
        return true;
      } else {
        return false;
      }
    }
  },

  isShow() {
    if (this.$route.path == "/" || this.$route.path == "/home") {
      return true;
    } else {
      return false;
    }
  },

  created() {
    this.getHotwords();
    this.number = this.$route.query.number;
  },
  methods: {
    getHotwords() {
      this.$get("home/statistics", { page: 1, limit: 50, ishot: 1 }).then(
        res => {
          this.words = res.items.data;
        }
      );
    },
    search(item) {
      this.searchData.goodsname = item.keywords;
      this.$router.push({
        path: "/searchProduct",

        query: this.searchData
      });
    },
    toSort(item1, item2) {
      if (!item2) {
        let routerJump = this.$router.resolve({
          path: "/sortProduct",
          query: { catid: item1.id, sort: item1.cate_name }
        });
        window.open(routerJump.href, "_blank");
      } else {
        let routerJump = this.$router.resolve({
          path: "/sortProduct",
          query: {
            catid: item2.id,
            sort: item2.cate_name,
            catid0: item1.id,
            sort0: item1.cate_name
          }
        });
        window.open(routerJump.href, "_blank");
      }
      // location.reload();
    },
    toHome() {
      this.number = 0;
      this.$router.push({
        path: "/",
        query: { number: this.number }
      });
    },
    toBrands() {
      this.number = 1;
      this.$router.push({
        path: "brands",
        query: { number: this.number }
      });
    },
    toship() {
      this.number = 2;
      this.$router.push({
        path: "/flagShip",
        query: { number: this.number }
      });
    },
    toLive() {
      window.open("http://live.861718.com/", "_blank");
    },
    toChain(){
      this.number = 4;
      this.$router.push({
        path: "/chain",
        query: { number: this.number }
      });
    },
    toMaterial(){
      this.number = 5;
      this.$router.push({
        path: "/material",
        query: { number: this.number }
      });
    },
    catMouseover(item) {
      this.catmouList = item;
      this.catlUL = true;
    },
    catMouseleave() {
      this.catlUL = false;
    },
    scover() {
      this.Scboxshow = true;
    },
    scleave() {
      this.Scboxshow = false;
    }
  },
  mounted() {
    this.$get("admin/system").then(res => {
      this.logo = res.items.find(i => i.name == "web_site_logo").value;
      console.log(" this.logo", this.logo);
    });
    //产品分类列表
    this.$get("/home/goodsCategory").then(res => {
      this.Category = res;
    });
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixins";
.a {
  color: #fe6925;
}
.B_l {
  background-color: #fff;
  width: 220px;
  z-index: 11;
  float: left;

  p {
    background-color: #000;
    height: 36px;
    line-height: 40px;

    font-size: 14px;
    color: #fff;
    margin-top: -36px;
  }

  .B_l_list {
    .catList {
      height: 460px;
      width: 700px;
      background: #fafafa;
      position: absolute;
      left: 220px;

      top: 0;
      .title {
        width: 100%;
        text-align: left;
        font-weight: bold;
        font-size: 14px;
      }
      dl::after {
        display: block;
        content: "";
        clear: both;
      }
      dl {
        width: 100%;

        dd {
          float: left;
          margin-right: 20px;
        }
        dd :hover {
          color: red;
        }
      }
      ul {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 10000000000000;
        background: #fff;
        color: #222;
        height: 459px;
        overflow-y: scroll;
        width: 600px;

        li {
          padding: 10px 10px;
          box-sizing: border-box;

          // margin-left: 15px;
          cursor: pointer;

          line-height: 35px;

          a {
            color: #222;
            font-size: 14px;
          }
        }
      }
    }

    // border:1px solid #fff;
    border-radius: 5%;

    // background-color:#fff;
    ul:nth-child(-n + 5) {
      border-bottom: 1px solid #ccc;
    }

    .cat_ul {
      // background-color: rgba(0,0,0,.55);

      background-color: #e94c15;
      width: 100%;
      height: 460px;
      position: relative;
      z-index: 11;
      .tit {
        @include space-between;
        i {
          color: #fff;
          margin-top: 5px;
        }
        color: #fff;
        padding: 10px;
        font-weight: bold;
        font-size: 14px;
      }
      .tit:hover {
        i {
          color: #666;
          margin-top: 5px;
        }
        background: #fff;
        position: relative;
        color: #000;
      }
      .usually {
        box-sizing: border-box;
        border-bottom: 1px solid #fe7c4f;
        margin: 0 10px;
        .item {
          width: 50%;
          float: left;
          color: #fff;
          padding: 5px 0;
        }
        padding-bottom: 10px;
      }
      .usually::after {
        content: "";
        display: block;
        clear: both;
      }
      .cat {
        // position: relative;

        width: 100%;
        position: relative;
        color: #fff;
        font-size: 14px;
        cursor: pointer;

        p {
          height: 30px;
          line-height: 30px;
          color: #fff;

          span {
            color: #fff;
          }
        }
      }
    }
  }
}
.overBox {
  background: #222;
  position: absolute;
  left: 0;
  top: 150px;
  z-index: 300;
}

.overcat-leave-active,
.overcat-enter-active {
  transition: all 0.5s ease;
}

.overcat-leave-active,
.overcat-enter {
  height: 0px !important;
}

.overcat-leave,
.overcat-enter-active {
  max-height: 460px;
}

.center {
  ul {
    padding: 10px 0;
    width: 100%;
    @include align-center;
    li {
      margin-right: 10px;
    }
  }
}
.code {
  width: 80px;
  height: 80%;
  float: right;
}
.home_top {
  background: #fff;
  border-bottom: 2px solid #e94c15;
}

.sort {
  background: #ce201e;
  width: 220px;
  color: #fff;

  padding: 0 0 0 10px;
  display: inline-block;
}
.com {
  position: relative;
  padding-top: 20px;
  width: 1226px;
  margin: 0 auto;
  box-sizing: border-box;

  .logo_img {
    img {
      width: 200px;
    }
  }

  .SG_ul {
    :nth-child(n + 2) {
      padding-left: 10px;
    }

    li {
      float: left;
      font-size: 14px;
      padding: 5px 0 0 0;
      color: #a8a8a8;
    }
  }
  .nav {
    @include space-between;
    .com_ul {
      @include row;
      padding: 10px;

      flex: 1;

      font-weight: bold;
      font-size: 16px;

      :hover {
        color: red;
        cursor: pointer;
      }

      :nth-child(n + 2) {
        margin-left: 24px;
      }

      li {
        padding: 0 30px;
        font-size: 16px;

        a {
          font-size: 16px;
          color: #222;
        }

        i {
          font-size: 18px;
        }
      }
    }
  }
}
.Search_Goods {
  position: absolute;
  left: 345px;
  top: 10px;
  overflow: hidden;
  width: 615px;
}

// .Shopping_Cart {
//   position: absolute;
//   height: auto;
//   top: 10px;
//   right: 0px;

//   cursor: pointer;
//   width: 200px;

//   .overBox {
//     position: absolute;
//     right: 0px;
//     top: 40px;
//     z-index: 3000000;
//     width: auto;
//     height: 300px;
//     overflow: hidden;
//   }

//   .overcat-leave-active,
//   .overcat-enter-active {
//     transition: all 0.5s ease;
//   }

//   .overcat-leave-active,
//   .overcat-enter {
//     height: 0px !important;
//   }

//   .overcat-leave,
//   .overcat-enter-active {
//     max-height: 320px;
//   }

//   p {
//     width: 160px;
//     float: right;
//     border: 2px solid #e94c15;
//     padding: 8px 18px;
//     background: #e94c15;

//     i {
//       font-size: 20px;
//       font-weight: bold;
//       color: #fff;
//       margin-right: 5px;

//       span {
//         width: 15px;
//         height: 15px;
//         margin-left: 10px;
//         line-height: 15px;
//         text-align: center;
//         background: #db2726;
//         color: #fff;
//         border-radius: 50%;
//       }
//     }

//     span {
//       color: #fff;
//       font-weight: bold;
//     }
//   }
// }

.box {
  width: 100%;
  height: 50px;
  background-color: #e94c15;
  overflow: hidden;
  position: fixed;
  top: 0;
  z-index: 111111;

  .box_center {
    height: 50px;
    width: 1226px;
    margin: 0 auto;

    // background: yellow;
    // line-height: 50px;
    h1 {
      float: left;
      color: #fff;
      font-size: 22px;
      margin-top: 5px;
    }
  }
}
.mybox-leave-active,
.mybox-enter-active {
  transition: all 0.5s ease;
}

.mybox-leave,
.mybox-enter-active {
  height: 50px;
}
</style>
