<template>
  <div class="list-top">
    <div class="search">
      <div class="border">
        <el-dropdown v-if='!isShow_nav' @command="handleCommand">
          <span class="el-dropdown-link">
            {{name}}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="0">
              {{data.name1}}
            </el-dropdown-item>
            <el-dropdown-item command="1">
              {{data.name2}}
            </el-dropdown-item>

          </el-dropdown-menu>
        </el-dropdown>

        <input v-if='searchData.name_id==0' v-model="searchData.goodsname" @keyup.enter="search" type="text" class="_inp" placeholder="请输入关键字" />

        <input v-else v-model="searchData.title" @keyup.enter="search" type="text" class="_inp" placeholder="请输入关键字" />

        <!-- <input
              v-model="searchData.title"
              @keyup="keyup($event)"
              @keydown="keydown($event)"
              type="text"
              class="_inp"
              placeholder="请输入关键字"
              @focus="mouseOver()"
              @blur="mouseLeave()"
              @input="changeArr()"
            /> -->

      </div>

      <el-button type="primary" slot="append" icon="el-icon-search " @click="search">搜全站</el-button>
      <el-button v-if='isShow_nav' type="info" slot="append" icon="el-icon-search " @click="search2(id)">搜本店</el-button>
    </div>
    <ul class="list-group" ref="ListGroup">
      <li v-for="(item,index) in arr" :key="index" :class="{'list-group-item-info':index==listIndex}" @click="click(item)">{{item.goods_name}}</li>
    </ul>
  </div>
</template>
<script>
export default {
  data() {
    return {
      name_id: "0",
      name: "商品",
      data: {
        name1: "商品",
        name2: "店铺"
      },
      searchData: {
        shopid:this.id,
        name_id: "",
        title: "",
        goodsname: "",
        sale_num_pr: undefined,
        price_pr: undefined,
        page: 1,
        limit: 30
      },
      arr: [], //用于储存关键词的搜索词条
      listIndex: -1 //设置初始索引，数组从0开始，因此初始成-1,
      // diu:''
    };
  },
  props: ["searchValue", "id", 'type'],
  computed: {
    //判断页面显示导航
    isShow_nav() {
      if (
        this.$route.path == "/shop" ||
        this.$route.path == "/detail" ||
        this.$route.path == "/shopInner"  
     
      
       
        
      ) {
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {
    "$route.query"() {
      if (!this.$route.query.goodsname) {

        this.searchData.goodsname = "";
      }
    },
    "searchData.name_id"() {
      if (this.searchData.name_id == 0) {
        this.name = this.data.name1;
        this.searchData.name_id = 0;
      } else {
        this.searchData.name_id = 1;
        this.name = this.data.name2;
      }
    }
  },

  methods: {
    handleCommand(command) {
      if (command == 0) {
        this.name = this.data.name1;
        this.searchData.name_id = 0;

        this.searchData.title = "";
      } else {
        this.searchData.goodsname = "";
        this.searchData.name_id = 1;
        this.name = this.data.name2;
      }
    },

    toShop() {
      let routeData = {};
      
        routeData = this.$router.resolve({
          path: "/shopInner",
          query: this.searchData
        });
    
 

      window.open(routeData.href, "_blank");
    },

    search2(id) {
      this.searchData.shopid = id;
      this.searchData.catids = undefined;


      this.toShop()
    },
    search() {
      console.log(1111)
      if (this.searchData.shopid) {
        this.searchData.shopid = undefined;
      }

      if (this.searchData.name_id == 0) {
        this.$router.push({
          path: "/searchProduct",
          query: this.searchData
        });
      } else {
        this.$router.push({
          path: "/searchShop",
          query: this.searchData
        });
      }
    },
    changeArr() {
      if (this.wd == "") {
        this.$refs.ListGroup.style.display = "none";
      } else {
        this.$refs.ListGroup.style.display = "block";
      }
    },
    mouseLeave() {
      setTimeout(() => {
        this.$refs.ListGroup.style.display = "none";
      }, 1000);
    },
    mouseOver() {
      // this.$refs.ListGroup.style.display = 'block'
    },

    //这个函数我们在input标签输入关键词的时候不断的给百度服务器发送请求获取搜索词条，并且不断的复制给data中的数组arr
    keyup(event) {
      //如果我按的是上下键，那么就不发送请求了
      if (event.keyCode == 38 || event.keyCode == 40 || event.keyCode == 13)
        return;
      setTimeout(() => {
        this.$get("/home/goods", {
          goods_name: this.wd
        }).then(res => {
          if (this.wd == "") {
            this.arr = [];
          } else {
            this.arr = res.items;
          }
        });
      }, 1000);
    },
    //监听鼠标的点击事件
    //如果鼠标点击某一行的文字，则点击事件中的event.target.data代表关键词
    //如果点击某一行的空白处，则点击事件中的event.target.innerText代表关键词
    //大家可以通过console.log(event)来查看关键词所在的位置
    click(event) {
      console.log(event);
      if (event != undefined) {
        this.wd = event.goods_name;
        let routerJump = this.$router.resolve({
          path: "/ProductCategoryListPage",
          query: {
            catid: event.goods_cate_id
          }
        });
        window.open(routerJump.href, "_blank");
      }
    },
    //监听键盘的事件
    //如果按down，则增加当前listIndex+1，因此arr[this.listIndex]就能代表当前的词条
    //我们通过对listIndex的修改来得到当前词条在arr中的索引，然后就可以得到词条的具体信息，然后发送请求了
    keydown(event) {
      //下键：40 上键：38
      if (event.keyCode == 38) {
        //按的上键
        this.listIndex--;
        if (this.listIndex < 0) {
          this.listIndex = this.arr.length - 1;
        }
        this.wd = this.arr[this.listIndex].goods_name;
        this.diu = this.arr[this.listIndex].goods_cate_id;
      } else if (event.keyCode == 40) {
        //说明你按的是下键
        this.listIndex++;
        if (this.listIndex > this.arr.length - 1) {
          this.listIndex = 0;
        }
        this.wd = this.arr[this.listIndex].goods_name;
        this.diu = this.arr[this.listIndex].goods_cate_id;
      } else if (event.keyCode == 13) {
        //如果你按的是enter，那么就跳转到百度搜索结果
        let routerJump = this.$router.resolve({
          path: "/ProductCategoryListPage",
          query: {
            catid: this.arr[this.listIndex].goods_cate_id
          }
        });
        window.open(routerJump.href, "_blank");
      }
    }
  },
  mounted() {
    // this.name = this.data.name1;
    this.searchData = Object.assign(this.searchData, this.$route.query);

    console.log(" this.searchData ", this.searchData);
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixins";
.p01 {
  height: 30px;
  line-height: 30px;
}
::v-deep .el-button {
  vertical-align: middle;
  border-radius: 0px;
}
.list-top {
  position: relative;
  width: 100%;
  .search {
    .border {
      @include space-between;
      flex: 1;
      border: 2px solid #e94c15;
      border-radius: 2px;
    }

    width: 100%;
    .el-dropdown {
      background: #eee;
      padding: 0 5px;
    }
    .el-dropdown-link {
      background: #eee;
      line-height: 40px;
      cursor: pointer;
      color: #666;
    }
    .el-icon-arrow-down {
      font-size: 12px;
    }
    @include space-between;
    ._inp {
      border: 0;
      vertical-align: middle;

      height: 40px;
      box-sizing: border-box;
      flex: 1;
      text-indent: 10px;
      font-size: 10px;
      color: #000;
      float: left;
    }
    ::v-deep .el-button {
      width: 100px;
    }
  }
}

.list-group {
  position: absolute;
  z-index: 10000;
  background-color: white;
  font-size: 14px;
  width: 100%;
  height: 290px;
  overflow: hidden;
  display: none;

  :hover {
    background-color: #e94c15;
    font-size: 12px;
    color: #fff;
  }
  .list-group-item-info {
    background-color: #e94c15;
    font-size: 12px;
    color: #fff;
  }
  :nth-last-child(1) {
    margin-bottom: 10px;
  }
  li {
    padding-left: 5px;
    padding-top: 3px;
    cursor: pointer;
    z-index: 8000;
  }
  .Search {
    cursor: pointer;
    position: absolute;
    top: 20%;
    left: 20%;
    width: 50px;
  }
}
</style>



